import { Container, Spacer, Text, Link } from "@nextui-org/react";
import useTranslation, { TranslatedMessage } from "./i18n";

export default function Footer() {
  const t = useTranslation();
  return (
    <footer>
      <Container
        sm
        css={{
          opacity: 0.5,
          "&:hover": { opacity: 1.0 },
          transition: "opacity 0.25s",
        }}
        lang="en"
      >
        <Spacer css={{ height: "$18" }} />
        <Text>
          {t.intl.locale === "ru" ? (
            // Russia has own age rating
            "Игра имеет возрастное ограничение 6+. Возрастная маркировка при игре по сети задаётся владельцами серверов и контент на них может быть запрещён для детей."
          ) : (
            <TranslatedMessage id="age.disclaimer" />
          )}{" "}
          <Link
            isExternal={true}
            target="_blank"
            href="https://www.minecraft.net/article/parents--guide-minecraft"
          >
            <TranslatedMessage id="age.disclaimer.more-info" />
          </Link>
        </Text>
        <Text>
          NOT AN OFFICIAL MINECRAFT PRODUCT. NOT APPROVED BY OR ASSOCIATED WITH
          MOJANG OR MICROSOFT.
        </Text>
        <Text>
          Legacy Launcher is in no way affiliated with Mojang Studios, nor
          should it be considered a project endorsed by Mojang Studios.
        </Text>
        <Text>
          If you have any questions, suggestions or complaints, please feel free
          to contact us:{" "}
          <Link href="mailto:support@llaun.ch">support@llaun.ch</Link>
        </Text>
        <Spacer css={{ height: "$4" }} />
      </Container>
    </footer>
  );
}
