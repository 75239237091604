import { FormattedMessage, IntlShape, useIntl } from "react-intl";

import * as original from "../lang/ru.json";
import * as defaults from "../lang/en.json";

export type TranslationId = keyof typeof original;

export type TranslationDict = Record<TranslationId, string>;

type Values = Record<string, JSX.Element | string | number>;

export function isTranslationId(id: string): id is TranslationId {
  return id in original;
}

function getDefaultOrOriginal(id: TranslationId): string {
  return id in defaults ? defaults[id] : original[id];
}

type JSXTranslationArgs = {
  id: TranslationId;
  values?: Values;
};

export class Translation {
  readonly intl: IntlShape;

  constructor(intl: IntlShape) {
    this.intl = intl;
  }

  formatMessage(id: TranslationId, values?: Values): string {
    const v = this.intl.formatMessage(
      {
        id,
        defaultMessage: getDefaultOrOriginal(id),
      },
      values
    );
    if (typeof v === "string") {
      return v;
    }
    console.warn(`missing or invalid translation for ${id}: ${typeof v}`);
    return id;
  }
}

export default function useTranslation(): Translation {
  const intl = useIntl();
  return new Translation(intl);
}

export function TranslatedMessage(args: JSXTranslationArgs) {
  const id = typeof args === "string" ? args : args.id;
  return (
    <FormattedMessage
      id={id}
      defaultMessage={getDefaultOrOriginal(id)}
      values={typeof args === "object" ? args.values : undefined}
    />
  );
}
